import styled from "styled-components";
import { isMobile, isMobileOnly } from "react-device-detect";

export const TagBlock = styled.div`
  border: 1px solid ${(props) => (props.isActive ? "#e5cb82" : "#000")};
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
  height: ${isMobileOnly ? "5vh" : "auto"};
  padding: 5px;
  margin-bottom: ${isMobileOnly && '2%'};
  border-radius: 5px;
  cursor: pointer;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  position: relative;
`;

export const TagBlockContainer = styled.div`
  position: relative;
  display: flex;
  margin-left: 5px;

  &:hover .close-tag {
    opacity: 1;
    bottom: -12px;
    max-width: 100px;
  }
`

export const CloseTagBlock = styled.div`
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: all 0.2s;
  cursor: pointer;
  display: block;
  background-color: red;
  width: 100%;
  color: #fff;
  text-align: center;
  border-radius: 0 0 5px 5px;
  font-size: 12px;
  border-left: 1px solid ${(props) => (props.isActive ? "#e5cb82" : "#000")};
  border-bottom: 1px solid ${(props) => (props.isActive ? "#e5cb82" : "#000")};
  border-right: 1px solid ${(props) => (props.isActive ? "#e5cb82" : "#000")};

  &:before {
    content: "X"
  }
`
