import React, { useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Toggle from "react-toggle";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { updateSettings } from "../../../actions/settingsActions";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Container,
  Body,
  PermanentDetailsContainer,
  AdditionalDetailsContainer,
  customStyles,
  Button,
  Fields,
  ButtonContainer,
  ToggleContainer,
  ToggleText,
} from "./calendar.styles";
import InputWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";
import DefaultSelect from "../../UiComponents/Select/Default";

const viewsOptions = [
  { value: "month", label: "חודש" },
  { value: "week", label: "שבוע" },
  { value: "day", label: "יום" },
  { value: "agenda", label: "אגנדה" },
  { value: "myweek", label: "יומי אופנועים" },
];
const pagesOptions = [
  { value: "calendar", label: 'calendar' },
  { value: "calendar-vehicle", label: 'calendarVehicle' },
];

const renderDatePickerField = ({
  input,
  placeholder,
  meta: { error, touched },
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <div>
      {placeholder}
      <DatePicker
        {...input}
        selected={input.value}
        dir={dir}
        placeholderText={placeholder}
        className={`${touched && error ? "error" : ""} date-input`}
        locale={i18n.language}
        onBlur={() => input.onBlur(input.value)}
        timeIntervals={60}
        timeCaption="Time"
        dateFormat="h:mm aa"
        showTimeSelect
        showTimeSelectOnly
      />
    </div>
  );
};

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle
        checked={input.value}
        onChange={(x) => input.onChange(x.target.checked ? 1 : 0)}
      />
    </ToggleContainer>
  );
};

const renderCheckBox = ({ input, label }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  );
};

let isTranslatedPages = false;

const Calendar = ({ handleSubmit, updateSettings, history }) => {
  const { t, i18n } = useTranslation("common");
  const [registrationFees, setRegistrationFees] = useState(false);
  const updateData = (formValues) => {
    updateSettings(formValues);
    history.push("/calendar");
  };
  if (!isTranslatedPages) {
    for (const k in Object.keys(pagesOptions)) {
      pagesOptions[k].label = t(`menuCategories.${pagesOptions[k].label}`)
    }
    isTranslatedPages = true;
  }

  return (
    <Container onSubmit={handleSubmit(updateData)}>
      <Body>
        <Fields>
          <PermanentDetailsContainer>
            <Field
              name="calendarStartTime"
              component={renderDatePickerField}
              placeholder={t("settings.calendarStartTime")}
            />
            <Field
              name="calendarEndTime"
              component={renderDatePickerField}
              placeholder={t("settings.calendarEndTime")}
            />
            <Field
              name="calendarSpacingInMinutes"
              component={InputWrappedWithField}
              placeholder={t("settings.spacingInMinutes")}
              type="number"
            />
            <Field
              name="eventTime"
              component={InputWrappedWithField}
              placeholder="זמן שיעור"
              type="number"
            />
            <Field
              name="defaultView"
              component={DefaultSelect}
              placeholder="תצוגת ברירת מחדל"
              options={viewsOptions}
            />
            <Field
              name="defaultPage"
              component={DefaultSelect}
              placeholder="דף ברירת מחדל"
              options={pagesOptions}
            />
            {/* <Field
              name="autoCompleteLesson"
              component={InputWrappedWithField}
              placeholder={t("settings.autoCompleteLesson")}
              type="number"
            /> */}
            {/* <Field
              name="showCanceledLessons"
              component={renderToggle}
              label={t("settings.showCanceledLessons")}
            />
            <Field
              name="showCanceledTest"
              component={renderToggle}
              label={t("settings.showCanceledTest")}
            />
            <Field
              name="showCanceledInternalTest"
              component={renderToggle}
              label={t("settings.showCanceledInternalTest")}
            /> */}
          </PermanentDetailsContainer>
          <AdditionalDetailsContainer>
            <Field
              name="showEventType"
              component={renderToggle}
              label={t("settings.showEventType")}
            />
            <Field
              name="showEventStatus"
              component={renderToggle}
              label={t("settings.showEventStatus")}
            />
            <Field
              name="showStudent"
              component={renderToggle}
              label={t("settings.showStudent")}
            />
            <Field
              name="showVehicle"
              component={renderToggle}
              label={t("settings.showVehicle")}
            />
            <Field
              name="showTime"
              component={renderToggle}
              label={t("settings.showTime")}
            />
            <Field
              name="showBalance"
              component={renderToggle}
              label={t("settings.showBalance")}
            />
            <Field
              name="showBranch"
              component={renderToggle}
              label={t("settings.showBranch")}
            />
            <Field
              name="showDrivingPermit"
              component={renderToggle}
              label={t("settings.showDrivingPermit")}
            />
            <Field
              name="showNotes"
              component={renderToggle}
              label={t("settings.showNotes")}
            />
            <Field
              name="showHealthDeclaration"
              component={renderToggle}
              label="אישור אירוע"
            />
            <Field
              name="showPickUpLocation"
              component={renderToggle}
              label={t("settings.showPickUpLocation")}
            />
            <Field
              name="showTeacher"
              component={renderToggle}
              label={t("settings.showTeacher")}
            />
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <Field
              name="showCanceled"
              component={renderToggle}
              label={t("settings.showCanceled")}
            />
            <Field
              name="showCanceledAndCharged"
              component={renderToggle}
              label={t("settings.showCanceledAndCharged")}
            />
            <Field
              name="showDailyBikes"
              component={renderToggle}
              label="יומי אופנועים"
            />
            <Field
              name="showCalendarVehicle"
              component={renderToggle}
              label="הצג רכב לוח שנה"
            />
          </AdditionalDetailsContainer>
        </Fields>
        <Fields>
          <Field name="sunday" component={renderCheckBox} label="יום ראשון" />
          <Field name="monday" component={renderCheckBox} label="יום שני" />
          <Field name="tuesday" component={renderCheckBox} label="יום שלישי" />
          <Field
            name="wednesday"
            component={renderCheckBox}
            label="יום רביעי"
          />
          <Field name="thursday" component={renderCheckBox} label="יום חמישי" />
          <Field name="friday" component={renderCheckBox} label="יום שישי" />
          <Field name="saturday" component={renderCheckBox} label="יום שבת" />
        </Fields>
      </Body>
      <ButtonContainer>
        <Button type="submit">{t("buttons.save")}</Button>
      </ButtonContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      calendarStartTime: new Date(state.session.user.calendarStartTime),
      calendarEndTime: new Date(state.session.user.calendarEndTime),
      calendarSpacingInMinutes: state.session.user.calendarSpacingInMinutes,
      eventTime: state.session.user.eventTime,
      autoCompleteLesson: state.session.user.autoCompleteLesson,
      showEventType: state.session.user.showEventType,
      showEventStatus: state.session.user.showEventStatus,
      showStudent: state.session.user.showStudent,
      showVehicle: state.session.user.showVehicle,
      showTime: state.session.user.showTime,
      showBalance: state.session.user.showBalance,
      showDailyBikes: state.session.user.showDailyBikes,
      showCalendarVehicle: state.session.user.showCalendarVehicle,
      showBranch: state.session.user.showBranch,
      showDrivingPermit: state.session.user.showDrivingPermit,
      showTeacher: state.session.user.showTeacher,
      showHealthDeclaration: state.session.user.showHealthDeclaration,
      showNotes: state.session.user.showNotes,
      sunday: state.session.user.sunday,
      monday: state.session.user.monday,
      tuesday: state.session.user.tuesday,
      wednesday: state.session.user.wednesday,
      thursday: state.session.user.thursday,
      friday: state.session.user.friday,
      saturday: state.session.user.saturday,
      showPickUpLocation: state.session.user.showPickUpLocation,
      showCanceled: state.session.user.showCanceled,
      showCanceledAndCharged: state.session.user.showCanceledAndCharged,
      defaultView: viewsOptions.find(
        (x) => x.value === state.session.user.defaultView
      ),
      defaultPage: pagesOptions.find(
        (x) => x.value === state.session.user.defaultPage
      ),
      id: state.session.user.settingsId,
    },
  };
}

export default withRouter(
  connect(mapStateToProps, { updateSettings })(
    reduxForm({
      form: "calendarSettings",
    })(Calendar)
  )
);
