/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, withRouter } from "react-router-dom";
import DefaultLayout from "../_layouts/default/Default";
import { tryLogin } from "../../../actions/session";
import LoginLayout from "../_layouts/login/Login";

class RouteWrapper extends PureComponent {
  componentDidMount() {
    const token = window.localStorage.getItem("token");
    const { session } = this.props;
    if (!session.isLoggedIn && token) {
      this.props.tryLogin(token);
    }
  }

  componentDidUpdate(prevProps) {
    const { session } = this.props;
    if (session.isLoggedIn !== prevProps.session.isLoggedIn) {      
      this.props.history.push(this.props.user.defaultPage ? this.props.user.defaultPage : "/calendar");
    }
  }

  render() {
    const {
      component: Component,
      isPrivate,
      session,
      location,
      ...rest
    } = this.props;

    /**
     * Redirect user to SignIn page if he tries to access a private route
     * without authentication.
     */
    if (isPrivate && !session.isLoggedIn) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.history.push("/");
    }

    if (isPrivate && session.isLoggedIn) {
      // eslint-disable-next-line react/destructuring-assignment
      if (
        !this.props.user[this.props.history.location.pathname.split("/")[1]] &&
        this.props.history.location.pathname.split("/")[1] !== "settings"
      ) {
        // this.props.history.push("/studentSearch");
      }
    }

    const Layout =
      location.pathname.toLocaleLowerCase() === "/"
        ? LoginLayout
        : DefaultLayout;

    /**
     * If not included on previous case, redirect user to the desired route.
     */
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} session={session} />
          </Layout>
        )}
      />
    );
  }
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

function mapStateToProps(state) {
  return { session: state.session, user: state.session.user };
}

export default withRouter(connect(mapStateToProps, { tryLogin })(RouteWrapper));
