import usersApi from "../apis/users";
import permissionsApi from "../apis/permissions";
import settingsApi from "../apis/settings";
import {
  USER_CREATE_SUCCESS,
  USER_CREATE_START,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_START,
  USER_SIGN_IN_FAILED,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILED,
  USER_LOGOUT_STARTED,
  USER_FETCH_SUCCESS,
  USER_FETCH_STARTED,
  USER_FETCH_FAILED,
  USER_UPDATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_FILTER_SUCCESS,
} from "../constants/actionTypes";
import { toastr } from "react-redux-toastr";
import { toast } from "react-toastify";

// TODO: added check if upload needed
async function uploadLogo(newLogo) {
  try {
    const formData = new FormData();
    formData.append("file", newLogo);
    const response = await usersApi.patch("/uploadLogo", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return response.data.uniqueFileName;
  } catch (error) {
    throw Error(error);
  }
}

export const userCreate = (user, reset) => async (dispatch) => {
  try {
    dispatch({
      type: USER_CREATE_START,
    });
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await usersApi.post(
      "/register",
      {
        ...user,
      },
      {
        headers,
      }
    );
    await permissionsApi.post("/createUserPermissions", {
      userId: user.userId,
    });
    await settingsApi.post("/create", {
      userId: response.data.id,
    });
    dispatch({
      type: USER_CREATE_SUCCESS,
      payload: { id: response.data.id, ...user },
    });
    reset();
  } catch (error) {
    toast.error("כישלון ביצירת משתמש");
  }
};

export const getUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const {
      schoolId,
      showCanceled,
      showCanceledAndCharged,
      branchId,
      userType,
    } = getState().session.user;
    const response = await usersApi.get(`/getAllUsersType`, {
      headers,
    });

    dispatch({
      type: USER_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    toastr.error("please try again");
    dispatch({
      type: USER_FETCH_FAILED,
    });
  }
};

export const userSignIn =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_SIGN_IN_START,
      });
      const response = await usersApi.post("/login", {
        email,
        password,
      });

      if (typeof response.data.verified !== "undefined") {
        toastr.error("please verify your email");

        dispatch({
          type: USER_SIGN_IN_FAILED,
        });

        return;
      }

      window.localStorage.setItem("token", response.data.token);

      dispatch({
        type: USER_SIGN_IN_SUCCESS,
        payload: response.data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: USER_SIGN_IN_FAILED,
      });
      toastr.error("please try again");
      return false;
    }
  };

export const logOut = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_STARTED,
    });
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await usersApi.post("/logout", null, {
      headers,
    });
    window.localStorage.removeItem("token");

    dispatch({
      type: USER_LOGOUT_SUCCESS,
    });

    toastr.success("Logged out", "hope to see you soon!");
  } catch (error) {
    dispatch({
      type: USER_LOGOUT_FAILED,
    });

    toastr.error("please try again");
  }
};

export const getFilters = () => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await usersApi.get("/filters", {
      headers,
    });

    dispatch({
      type: USER_FILTER_SUCCESS,
      payload: response.data,
    });

  } catch (error) {
    toastr.error("please try again");
  }
};

export const addFilter = ({ params, name, type }) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await usersApi.post("/filter", { params, name, type }, {
      headers,
    });
    getFilters()(dispatch);
  } catch (error) {
    toastr.error("please try again");
  }
};

export const deleteFilter = (id) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await usersApi.delete(`/filter/${id}`, {
      headers,
    });
    getFilters()(dispatch);
  } catch (error) {
    toastr.error("please try again");
  }
};

export const userForgotPassword = (email) => async () => {
  try {
    await usersApi.post("/forgotPassword", {
      email,
    });

    toastr.info("check your email");
  } catch (error) {
    toastr.error("please try again");
  }
};

export const userUpdateData = (user) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await usersApi.patch(
      "/updateUserData",
      {
        ...user,
      },
      {
        headers,
      }
    );

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: user,
    });
    return true;
  } catch (error) {
    toastr.error("please try again");
    return false;
  }
};
export const updateBasicData = (user) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await usersApi.patch(
      "/updateBasicData",
      {
        ...user,
      },
      {
        headers,
      }
    );

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: user,
    });
    return true;
  } catch (error) {
    toastr.error("please try again");
    return false;
  }
};

export const setUserDisabled = (userId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await usersApi.patch(
      "/disable",
      {
        userId,
      },
      {
        headers,
      }
    );
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: { id: userId, isActive: 2 },
    });
  } catch (error) {
    toast.error("כישלון בעדכון המשתמש");
  }
};

export const setUserActive = (userId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await usersApi.patch(
      "/activate",
      {
        userId,
      },
      {
        headers,
      }
    );

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: { id: userId, isActive: 1 },
    });
  } catch (error) {
    toast.error("כישלון בעדכון המשתמש");
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await usersApi.delete(
      "/deleteUser",
      {
        data: { userId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: { id: userId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת המשתמש");
  }
};
