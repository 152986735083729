import settingsApi from "../apis/settings";
import {
  VEHICLE_FETCH_FAILED,
  VEHICLE_FETCH_STARTED,
  VEHICLE_FETCH_SUCCESS,
  UPDATE_SESSION,
} from "../constants/actionTypes";
import { toast } from "react-toastify";
import { reset } from "redux-form";

//TODO: added teacherId and schoolId
export const createSettings = (settings) => async (dispatch) => {
  try {
    // dispatch({
    //   type: VEHICLE_FETCH_STARTED,
    // });
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await settingsApi.post(
      "/create",
      {
        settings,
      },
      {
        headers,
      }
    );

    // dispatch({
    //   type: VEHICLE_FETCH_SUCCESS,
    // });
  } catch (error) {
    toast.error("כישלון ביצירת הסכם");

    // dispatch({
    //   type: VEHICLE_FETCH_FAILED,
    // });
  }
};

export const updateSettings = (settings) => async (dispatch, getState) => {
  try {
    // dispatch({
    //   type: VEHICLE_FETCH_STARTED,
    // });
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { id } = getState().session.user;
    if (settings.defaultView) {
      settings = {
        ...settings,
        defaultView: settings.defaultView.value,
      };
    }
    if (settings.defaultPage) {
      settings = {
        ...settings,
        defaultPage: settings.defaultPage.value,
      };
    }
    const response = await settingsApi.patch(
      "/update",
      {
        settings: {
          ...settings,
          userId: id,
        },
      },
      {
        headers,
      }
    );
    delete settings.id;
    dispatch({
      type: UPDATE_SESSION,
      payload: settings,
    });
    return response.data;
  } catch (error) {
    // dispatch({
    //   type: VEHICLE_FETCH_FAILED,
    // });
  }
};
