import React from "react";
import { TagBlockContainer, TagBlock, CloseTagBlock } from "./tag.styles";

const getTags = (options, isActive, onClick, onClose) => {
  if (!options) return '';
    const data = options.map((v) => {
      const activeItem = isActive && isActive(v);
      return <TagBlockContainer>
      <TagBlock title={v.name} isActive={activeItem} onClick={() => onClick?  onClick(v) : ''} >
        {v.name}
      </TagBlock>
      {onClose && <CloseTagBlock isActive={activeItem} title="מחק פריט" className="close-tag" onClick={() => onClose(v)} /> }
      </TagBlockContainer>
    });
    return data;
  }

const TagList = ({
  onClick,
  onClose,
  options,
  isActive,
  className = '',
  style = {}
}) => (

  <>
    {getTags(options, isActive, onClick, onClose)}
  </>
);

export default TagList;
