import React, { useState } from "react";
import { connect } from "react-redux";
import { Icon, IconsAndTextContainer, TextAfterIcon, TitleContainer, Container } from "./eventView.styles";
import { sendWhatsAppEventMessage, eventStatusIcons, getNameStyle } from "../../../utils/events";


const EventViewVehicle = (props) => {
  const event = props.event;
  const [colors, setColors] = useState({background: props.chosenStudentColor, color: props.fontChosenStudentColor});
  // const vehicle = props.vehicles.find((v) => v.id == props.event.vehicleId);
  // const licenseType = props.licenseTypes.find((v) => v.id == vehicle ? vehicle.licenseType : 0);
  // return (<><span>#{vehicle ? vehicle.vehicleNumber : '0000000'} {vehicle ? vehicle.description : '-'}</span>: <span>{licenseType ? licenseType.name : '-'}</span></>);
  return (
    <Container
      chosenStudentColor={colors.background}
      fontChosenStudentColor={colors.color}
      isStudentChosen={
        typeof props.chosenStudentId !== "undefined" &&
        props.chosenStudentId == event.studentId
      }
    >
      <TitleContainer>
          {/* {props.showEventStatus ? (
            <IconsAndTextContainer
              onClick={() => props.openEventStatusChangeModal(props.event)}
              style={{ margin: 0 }}
            >
              <Icon
                className={eventStatusIcons[props.event.eventStatus]}
                style={{ margin: 0 }}
                aria-hidden="true"
              />
            </IconsAndTextContainer>
          ) : null}
          {props.showStudent && typeof props.event.firstName === "string" ? (
              <Icon
                className="icons8-whatsapp"
                onClick={() => sendWhatsAppEventMessage(event, event, props.user)}
                data-tip={`<span dir="auto">${event.firstName} ${event.lastName}</span><br/><span dir="auto">${event.address}, ${event.city}</span><br/><span dir="auto">${event.phone}</span>`}
                data-for={event.id.toString()}
              />
          ) : null} */}
          <IconsAndTextContainer
              onClick={() =>
                props.onStudentClick({
                  event: event,
                  value: event.studentId,
                  label: `${event.firstName} ${event.lastName}`,
                })
              }
            >
            <TextAfterIcon
              isDay={props.isDay}
              style={{
                ...getNameStyle(event),
              }}
            >
              {event.firstName}&nbsp;
              {event.nickname && `(${event.nickname}) `}
              {event.lastName}
            </TextAfterIcon>
            </IconsAndTextContainer>
          </TitleContainer>
        </Container>);
}

function mapStateToProps(state) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    showEventStatus: state.session.user.showEventStatus,
    showStudent: state.session.user.showStudent,
    chosenStudentColor: state.session.user.chosenStudentColor,
    fontChosenStudentColor: state.session.user.fontChosenStudentColor,
  };
}

export default connect(mapStateToProps, {})(EventViewVehicle);
